<template>
	<div class="chart-holder" :class="addclasses" :style="'height: '+this.holderHeight+'px;'">
		<div class="chart-container" :style="'height: '+this.chartHeight+'px;'">
			<div style="width: 100%; height: 100%;">
				<slot name="default"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'ChartHolderDash',
	mixins: [globalMixin],
	components: {},
	props: {
		addclasses: String,
		height: Number
	},
	data() {
		return {
			store: store,
		}
	},
	computed: {
		chartHeight() {
			if(this.height) return this.height;
			else return 500;
		},
		holderHeight() {
			if(this.height) return this.height + 86;
			else return 586;
		}
	},
	methods: {

	},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.chart-container {
	padding: 20px 0 0;
	justify-content:center;
	position: relative;
	display: flex;
	width: 100%;
}
.quarter .headsection {
	height: 40px;
}
.quarter .headsection .chtitle {
	font-size: 13px;
}
.quarter .chart-container {
	padding-top: 10px;
}
</style>
