<template>
	<router-view></router-view>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'App',
	components: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,



		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {
		// let queryString = window.location.search
		// let urlParams = new URLSearchParams(queryString)
		// if(urlParams.has('iframe')) {
		// 	let isIframe = urlParams.get('iframe')
		// 	if(isIframe === '1' || isIframe === 'true' || isIframe === true) {
		// 		this.store.inIframe = true
		// 	}
		// }
	},
}
</script>

<style>

</style>
