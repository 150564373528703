<template>
	<div class="toggler">
		<div class="leftlabel" @click="toggleTo('left')">{{leftlabel}}</div>
		<div class="togglehold" :class="{righter : choice === 'right'}">
			<div class="toggleclickers">
				<div class="togglehalf" @click="toggleTo('left')"></div>
				<div class="togglehalf" @click="toggleTo('right')"></div>
			</div>
			<div class="togglecircle" :class="{righter : choice === 'right'}"></div>
		</div>
		<div class="rightlabel" @click="toggleTo('right')">{{rightlabel}}</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'TogglerSwitch',
	mixins: [globalMixin],
	props: {
		leftlabel: {
			type: String,
			default: ''
		},
		leftval: {
			type: String,
			default: ''
		},
		rightlabel: {
			type: String,
			default: ''
		},
		rightval: {
			type: String,
			default: ''
		},
	},
	data: function () {
		return {
			store: store,
			choice: 'left',
		}
	},
	computed: {

	},
	methods: {
		toggleTo(direction) {
			this.choice = direction
			let mychoice = this.leftval
			if(direction === 'right') {
				mychoice = this.rightval
			}
			this.$emit('changeval', mychoice)
		},
	},
}
</script>

<style scoped>
.toggler {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
}
.togglehold {
	width: 50px;
	height: 25px;
	border-radius: 20px;
	display: flex;
	position: relative;
	cursor: pointer;
	background-color: #00AEEF;
	transition: background-color 0.1s ease-in-out;
	box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);
	border: 1px solid #888;
}
.togglehold.righter {
	background-color: #E81F76;
}
.toggleclickers {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
}
.togglehalf {
	width: 50%;
	height: 100%;
}
.togglecircle {
	width: 44%;
	height: 84%;
	margin: 4%;
	background-color: #966db1;
	position: absolute;
	border-radius: 20px;
	transition: margin 0.1s ease-in-out;
}
.togglecircle.righter {
	margin-left: 51%;
}
.leftlabel, .rightlabel {
	cursor: pointer;
	font-weight: bold;

}
</style>
