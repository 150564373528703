<template>
	<div class="nodatadiv">
		<p class="headline">No public data available for these selections.</p>
		<div>
			<p>The data in these charts & tables reflects what is publicly released by the survey provider.</p>
			<p>Some daypart, demographic and data type combinations are not publicly released.</p>
			<p>In general, dayparts are only released at the 10+ level, and demographics are only released for the Overall ROS daypart.</p>
			<div v-if="source === 'gfk'">
				<p>DAB data is only released in GFK Metro markets, and only Cume for the Overall ROS daypart.</p>
				<p>Radio Type splits (Streaming vs AM/FM/DAB) is only released in GFK Metro markets by daypart, Share & Cume only for the Overall ROS daypart.</p>
				<p>To view the full data set, please use your CRA Gold Standard certified software (eg GFK Probe, Nielsen Fusion/ERAM, or Audology).
					You will also require a current CRA metro data subscription, which is administered by GFK.
				</p>
			</div>
			<div v-else-if="source === 'xtra'">
				<p>To view the full data set, please use your CRA Gold Standard certified software (eg Frequency or Audology).</p>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'NoDataNotice',
	mixins: [globalMixin],
	props: {
		source: {
			type: String,
			default: 'gfk'
		},
		demoid: Number,
		daypartid: Number,
		datatype: String,
		band: String,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>

<style scoped>
.nodatadiv {
	padding: 30px;
}
.nodatadiv .headline {
	font-weight: bold;
	font-size: 18px;
}
.nodatadiv p {
	line-height: 2;
	font-size: 15px;
}
</style>
