import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueApexCharts from 'vue3-apexcharts'

import MainPage from "@/components/pages/MainPage.vue";
import WaybackMachine from "@/components/pages/WaybackMachine.vue";

import '@/includes/style.css'

//routing
const routes = [
	{path: '/wayback-machine', component: WaybackMachine},
	{path: '/', component: MainPage},
]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const app = createApp(App)
app.use(router);
app.use(VueApexCharts);
app.mount('#app')